import React from 'react';

import Box, { BoxProps } from 'components/Box';

type Props = BoxProps;

const OuterWrap: React.FC<Props> = (props) => {
  return <Box as="section" py={[8, 9]} px={5} {...props} />;
};

export default OuterWrap;
