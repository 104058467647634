import React from 'react';

import Box, { BoxProps } from 'components/Box';

type Props = BoxProps;

const OverlayBox: React.FC<Props> = (props) => {
  return (
    <Box
      position="absolute"
      left={0}
      top={0}
      width="100%"
      height="100%"
      bg="rgba(255, 255, 255, 0.5)"
      {...props}
    />
  );
};

export default OverlayBox;
