import { graphql } from 'gatsby';
import React from 'react';

import { BaseSanityPage } from 'types/schema';
import Box from 'components/Box';
import ContactInfoSection from 'components/contact/ContactInfoSection';
import H1 from 'components/H1';
import InnerWrap from 'components/layout/InnerWrap';
import MainLayout from 'components/layout/MainLayout';
import OuterWrap from 'components/layout/OuterWrap';

export const pageQuery = graphql`
  query ContactPageQuery {
    sanityContactPage(id: { eq: "-6311880a-f7cf-5133-b4e6-78a4a5a63244" }) {
      meta {
        title
        description
      }
      pageTitle
    }
  }
`;
type QueryData = {
  sanityContactPage: BaseSanityPage;
};

const ContactPage: React.FC<{ data: QueryData }> = ({ data }) => {
  const {
    meta: { title, description },
    pageTitle,
  } = data.sanityContactPage;
  return (
    <MainLayout seoProps={{ title, description, pagePath: '/contact/' }}>
      <OuterWrap>
        <InnerWrap>
          <H1 textAlign="center">{pageTitle}</H1>
        </InnerWrap>
      </OuterWrap>
      <ContactInfoSection />
      <Box
        bg="grey300"
        height={400}
        justifyContent="center"
        sx={{ iframe: { border: 0, height: '100%', width: '100%' } }}
      >
        <iframe
          title="Map of PLG Office Location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2886.9775136041308!2d-79.3839325845024!3d43.64863617912141!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b351994b20b0f%3A0x5951dc99b6fc300c!2sPerks%20Law%20Group!5e0!3m2!1sen!2sca!4v1602772655771!5m2!1sen!2sca"
          frameBorder="0"
          aria-hidden="false"
        />
      </Box>
    </MainLayout>
  );
};

export default ContactPage;
