import React from 'react';

import Box, { BoxProps } from 'components/Box';

type Props = BoxProps;

const H1: React.FC<Props> = (props) => {
  return <Box as="h1" variant="header1Serif" {...props} />;
};

export default H1;
