import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import Box from 'components/Box';
import ContactForm from 'components/contact/ContactForm';
import Flex from 'components/Flex';
import InnerWrap from 'components/layout/InnerWrap';
import OuterWrap from 'components/layout/OuterWrap';
import OverlayBox from 'components/OverlayBox';
import contactFormBg from 'images/contact-form-bg.jpg';
import SocialLinks from 'components/SocialLinks';
import { formatPhoneNumber, getPhoneNumberHref } from 'utils/helpers';

// type Props = {};

const ContactInfoSectionQuery = graphql`
  query ContactInfoSectionQuery {
    sanityCompanyDetails(_id: { eq: "companyDetails" }) {
      phoneNumber
      address {
        name
        addressLine1
        addressLine2
        city
        province
        postalCode
      }
    }
  }
`;

const ContactInfoSection: React.FC = () => {
  const {
    sanityCompanyDetails: { phoneNumber, address },
  } = useStaticQuery(ContactInfoSectionQuery);
  return (
    <OuterWrap
      sx={{ backgroundImage: `url(${contactFormBg})`, backgroundSize: 'cover' }}
      position="relative"
    >
      <OverlayBox bg="rgba(255, 255, 255, 0.92)" />
      <InnerWrap position="relative">
        <Flex justifyContent="center">
          <Box as="h2" variant="header3Serif" mb={7} textAlign="center" maxWidth={600}>
            Contact us directly or provide your information for a free callback
          </Box>
        </Flex>
        <Flex alignItems="flex-start" flexDirection={['column', 'row']}>
          <Box width={['100%', 1 / 2]}>
            <Box as="h4" variant="header5Serif">
              Our Hours
            </Box>
            <Box mt={2}>
              Mon: 8:30 a.m. - 5:30 p.m.
              <br />
              Tue: 8:30 a.m. - 5:30 p.m.
              <br />
              Wed: 8:30 a.m. - 5:30 p.m.
              <br />
              Thu: 8:30 a.m. - 5:30 p.m.
              <br />
              Fri: 8:30 a.m. - 5:30 p.m.
              <br />
            </Box>
            <Box mt={6} variant="body1">
              {address.name && (
                <>
                  {address.name}
                  <br />
                </>
              )}
              {address.addressLine1}
              {!!address.addressLine2 && `, ${address.addressLine2}`}
              <br />
              {address.city}, {address.province} {address.postalCode}
              <br />
              (By Appointment Only)
            </Box>
            <Box mt={4} variant="body1">
              Email: <a href="mailto:info@perkslawgroup.com">info@perkslawgroup.com</a>
            </Box>
            <Box mt={4} variant="body1">
              Telephone:{' '}
              <a href={getPhoneNumberHref(phoneNumber)}>{formatPhoneNumber(phoneNumber)}</a>
            </Box>
            <SocialLinks mt={4} />
          </Box>
          <Box width={['100%', 1 / 2]} ml={[0, 4]} mt={[4, 0]}>
            <Box as="h2" variant="header3Serif" mb={6}></Box>
            <ContactForm />
          </Box>
        </Flex>
      </InnerWrap>
    </OuterWrap>
  );
};

export default ContactInfoSection;
