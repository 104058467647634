import React from 'react';

import { contentMaxWidth } from 'components/theme/measurements';
import Box, { BoxProps } from 'components/Box';

type Props = BoxProps;

const InnerWrap: React.FC<Props> = (props) => {
  return <Box height="100%" maxWidth={contentMaxWidth} m="0 auto" {...props} />;
};

export default InnerWrap;
